import React from 'react';

const Header = () => {

    return (
        <div>
            <h1>Home Page</h1><br />
        </div>
    )
}

export default Header